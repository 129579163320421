import { useQuiz } from './use-quiz'
import * as UI from '@/ui'

export type BackButtonProps = {
  children?: React.ReactNode
} & UI.Form.ButtonProps

export const BackButton = ({ children, ...props }: BackButtonProps) => {
  const { back } = useQuiz()

  return (
    <UI.Form.Button color="outline" arrow={false} onClick={back} {...props}>
      {children}
    </UI.Form.Button>
  )
}

BackButton.displayName = 'Quiz.PrevButton'

export default BackButton
