import { useFormikContext } from 'formik'
import { FormikValues } from 'formik/dist/types'
import { useEffect } from 'react'

import {
  CheckboxGroupProps,
  RadioGroupProps,
  TextProps,
  TextareaProps,
  BooleanProps,
  DatePickerProps,
  SelectProps,
} from '../form'
import { ValidateDepends, ValidateDependsValue, dependsMatch } from '../form/validation'
import { useQuiz } from './use-quiz'
import * as UI from '@/ui'

export type QuestionProps =
  | QuestionPropsText
  | QuestionPropsTextarea
  | QuestionPropsBooleanRadio
  | QuestionPropsBooleanCheckbox
  | QuestionPropsRadio
  | QuestionPropsCheckbox
  | QuestionPropsRange
  | QuestionPropsDatePicker
  | QuestionPropsFile
  | QuestionPropsSelect
  | QuestionPropsMultiselect

export type QuestionDependsValue = ValidateDependsValue

export type QuestionDepends = ValidateDepends

export type QuestionPropsCommon = {
  children?: React.ReactNode
  name: string
  depends?: QuestionDepends | QuestionDepends[]
  helpTextAbove?: string
  helpTextBelow?: string
  readOnly?: 'readOnly'
}

export type QuestionPropsText = Pick<TextProps, 'placeholder' | 'onKeyUp'> & {
  type: TextProps['field']
  boxSize?: TextProps['boxSize']
  label?: never
  options?: never
  optionStyle?: never
  disabled?: boolean
} & QuestionPropsCommon

export type QuestionPropsTextarea = Pick<TextareaProps, 'placeholder'> & {
  type: 'textarea'
  label?: never
  options?: never
  optionStyle?: never
  onKeyUp?: never
  disabled?: boolean
} & QuestionPropsCommon

export type QuestionPropsBooleanRadio = {
  type: 'boolean_radio'
  label?: never
  options?: never
  onKeyUp?: never
  disabled?: boolean
} & Pick<BooleanProps, 'optionStyle'> &
  QuestionPropsCommon

export type QuestionPropsBooleanCheckbox = {
  type: 'boolean_checkbox'
  label: string
  options?: never
  onKeyUp?: never
  disabled?: boolean
} & Pick<BooleanProps, 'optionStyle'> &
  QuestionPropsCommon

export type QuestionPropsRadio = {
  type: 'radio'
  label?: never
  onKeyUp?: never
  disabled?: boolean
  buttonSize?: 'full' | 'fit' | 'sm' | 'md' | 'lg'
} & Pick<RadioGroupProps, 'options' | 'optionStyle'> &
  QuestionPropsCommon

export type QuestionPropsSelect = {
  type: 'select'
  label?: never
  optionStyle?: never
  onKeyUp?: never
  disabled?: boolean
} & Pick<SelectProps, 'options'> &
  QuestionPropsCommon

export type QuestionPropsCheckbox = {
  type: 'checkbox'
  label?: never
  onKeyUp?: never
  parentLabel?: string
  disabled?: boolean
} & Pick<CheckboxGroupProps, 'options' | 'optionStyle'> &
  QuestionPropsCommon

export type QuestionPropsMultiselect = {
  type: 'multiselect'
  label?: never
  optionStyle?: never
  onKeyUp?: never
} & Pick<SelectProps, 'options'> &
  QuestionPropsCommon

export type QuestionPropsRange = {
  type: 'range'
  label?: never
  options?: (string | number)[]
  optionStyle?: never
  onKeyUp?: never
} & QuestionPropsCommon

export type QuestionPropsDatePicker = {
  type: 'date' | 'datetime'
  label?: string
  options?: never
  optionStyle?: never
  onKeyUp?: never
  disabled?: boolean
  boxSize?: DatePickerProps['boxSize']
} & Pick<DatePickerProps, 'placeholder'> &
  QuestionPropsCommon

export type QuestionPropsFile = {
  type: 'file'
  label?: never
  options?: never
  optionStyle?: never
  onKeyUp?: never
} & QuestionPropsCommon

export const Question = ({
  type = 'text',
  label,
  name,
  optionStyle,
  depends,
  options,
  children,
  ...props
}: QuestionProps) => {
  const formik = useFormikContext()
  const quiz = useQuiz()

  const values = { ...quiz.values, ...(formik?.values ? (formik.values as FormikValues) : {}) }

  const hidden = depends && !dependsMatch(depends, values)

  useEffect(() => {
    if (quiz.isReady && quiz.values[name]) {
      formik.setFieldValue(name, quiz.values[name])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quiz.isReady])

  return (
    <div className={hidden ? 'hidden' : ''}>
      <div className="text-base">{children}</div>
      {
        {
          text: <UI.Form.Text name={name} field="text" {...props} />,
          textarea: <UI.Form.Textarea name={name} {...(props as Omit<TextareaProps, 'name'>)} />,
          password: <UI.Form.Text name={name} field="text" {...props} />,
          email: <UI.Form.Text name={name} field="text" {...props} />,
          boolean_checkbox: (
            <UI.Form.Boolean
              name={name}
              optionStyle={(optionStyle as CheckboxGroupProps['optionStyle']) || 'button'}
              field="checkbox"
              label={label as string}
              {...props}
            />
          ),
          boolean_radio: (
            <UI.Form.Boolean
              name={name}
              optionStyle={(optionStyle as RadioGroupProps['optionStyle']) || 'button'}
              field="radio"
              {...props}
            />
          ),
          radio: (
            <UI.Form.RadioGroup
              name={name}
              optionStyle={(optionStyle as RadioGroupProps['optionStyle']) || 'button'}
              options={options as RadioGroupProps['options']}
              {...props}
            />
          ),
          checkbox: (
            <UI.Form.CheckboxGroup
              name={name}
              optionStyle={optionStyle as CheckboxGroupProps['optionStyle']}
              options={options as CheckboxGroupProps['options']}
              {...props}
            />
          ),
          range: (
            <UI.Form.RadioGroup
              name={name}
              optionStyle="range"
              options={options as RadioGroupProps['options']}
              {...props}
            />
          ),
          datetime: <UI.Form.DatePicker field="datetime" name={name} {...props} />,
          date: <UI.Form.DatePicker field="dateOfBirth" name={name} {...props} />,
          file: <UI.Form.File name={name} {...props} />,
          select: <UI.Form.Select name={name} options={options as RadioGroupProps['options']} {...props} />,
          multiselect: (
            <UI.Form.Select multiselect name={name} options={options as CheckboxGroupProps['options']} {...props} />
          ),
        }[type]
      }
    </div>
  )
}

Question.displayName = 'Quiz.Question'

export default Question
