import { FormikValues } from 'formik/dist/types'
import { QuizProvider, UseQuizResult } from './use-quiz'

export type QuizProps<Value extends FormikValues = FormikValues> = {
  id?: string
  onSubmit?: (Value: Value) => void
  children: React.ReactNode | ((result: UseQuizResult) => React.ReactNode)
  className?: string
}

export const Quiz = function <Value extends FormikValues = FormikValues>({
  id,
  onSubmit,
  children,
  className,
}: QuizProps<Value>) {
  return (
    <QuizProvider<Value> onSubmit={onSubmit} id={id}>
      {(quiz) => (
        <div className={`${className} quiz`} id="quiz">
          {typeof children === 'function' ? children(quiz) : children}
        </div>
      )}
    </QuizProvider>
  )
}

Quiz.displayName = 'Quiz.Quiz'

export default Quiz
