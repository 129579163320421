import { useUi } from '../use-ui'
export type HeadGaps = 'none' | 'xs' | 'small' | 'medium' | 'large' | 'xl'

export type HeadProps = {
  gap?: HeadGaps
  className?: string
  children: React.ReactNode
}

export const Head = ({ gap = 'medium', className, children }: HeadProps) => {
  const gaps = {
    none: '',
    xs: `py-1 px-2`, // Tailwind: sm:py-1 sm:px-2  md:py-1 md:px-2  lg:py-1 lg:px-2  xl:py-1 xl:px-2
    small: `py-2 px-3`, // Tailwind: sm:py-2 sm:px-3  md:py-2 md:px-3  lg:py-2 lg:px-3  xl:py-2 xl:px-3
    medium: `py-3 px-4`, // Tailwind: sm:py-3 sm:px-4  md:py-3 md:px-4  lg:py-3 lg:px-4  xl:py-3 xl:px-4
    large: `py-4 px-5`, // Tailwind: sm:py-4 sm:px-5  md:py-4 md:px-5  lg:py-4 lg:px-5  xl:py-4 xl:px-5
    xl: `py-5 px-6`, // Tailwind: sm:py-5 sm:px-6  md:py-5 md:px-6  lg:py-5 lg:px-6  xl:py-5 xl:px-6
  }

  const ui = useUi({
    styles: {
      gap: { options: gaps, selected: gap },
    },
    className: `${className} bg-selphWhite-200`,
    name: 'Panel.Body',
  })

  return <div className={ui.className}>{children}</div>
}

Head.displayName = 'Panel.Head'

export default Head
