import { FormikValues } from 'formik/dist/types'
import { FormikHelpers } from 'formik'
import { useEffect } from 'react'
import { FormikFormProps } from '../form/form/formik-form'
import { useNotification } from '../use-notification'
import { useQuiz } from './use-quiz'
import * as UI from '@/ui'

export type PageFormProps<Value> = {
  name: string
  onSubmit?: (values: Value, formikHelpers: FormikHelpers<Value>) => void | Promise<any> | boolean
} & Omit<FormikFormProps<Value>, 'onSubmit'>

const scrollToTop = () => {
  const parent = document.getElementById('quiz')
  parent && parent.scrollIntoView({ behavior: 'smooth' })
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const PageForm = function <Value extends FormikValues = FormikValues>({
  name,
  children,
  onSubmit,
  initialValues,
  ...props
}: PageFormProps<Value>) {
  const quiz = useQuiz()
  const { clearMessage } = useNotification()
  const { submitPage, registerPage, currentPage, isReady } = quiz

  useEffect(() => {
    if (isReady) {
      registerPage({ name, initialValues })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  const submit = async (values: Value, helpers: FormikHelpers<Value>) => {
    clearMessage()
    if (!onSubmit || (onSubmit && (await onSubmit(values, helpers)) === false ? false : true)) {
      onSubmit && onSubmit(values, helpers)
      submitPage({ name, values, isValid: true })
      scrollToTop()
    }

    helpers.setSubmitting(false)
  }

  if (currentPage !== name) return null

  return (
    <UI.Form.Form type="formik" initialValues={initialValues} onSubmit={submit} {...props} enableReinitialze={true}>
      {(formik) => <UI.Block>{typeof children === 'function' ? children(formik) : children}</UI.Block>}
    </UI.Form.Form>
  )
}

PageForm.displayName = 'Quiz.Page'

export default PageForm
