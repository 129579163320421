export type FootProps = {
  children: React.ReactNode
}

export const Foot = ({ children }: FootProps) => {
  return <div className="bg-selphWhite-200 px-4 py-3">{children}</div>
}

Foot.displayName = 'Panel.Foot'

export default Foot
