import { createContext, useContext, useState, useReducer, Reducer, useEffect } from 'react'
import { FormikValues } from 'formik/dist/types'
import { useRouter } from 'next/router'
import { v4 as uuid } from 'uuid'
import { useStorage } from '../../use-storage'
import { useNotification } from '../../ui/use-notification'
import { getParam } from '../../../helpers/params'

export type UseQuizValues = { [key: string]: any }

export type UseQuizResultPage<Value extends UseQuizValues = UseQuizValues> = {
  values: Value | UseQuizValues
  isValid: boolean
  isComplete: boolean
}
export type UseQuizResultPages<Value extends UseQuizValues = UseQuizValues> = {
  [key: string]: UseQuizResultPage<Value>
}

export type UseQuizResult<Value extends UseQuizValues = UseQuizValues> = {
  back: () => void
  skip: (page: string) => void
  currentPage: string | undefined
  pages: UseQuizResultPages<Value>
  values: Value | UseQuizValues
  registerPage: ({
    name,
    initialValues,
    isValid,
    isComplete,
  }: {
    name: string
    initialValues: Partial<Value | UseQuizValues>
    isValid?: boolean
    isComplete?: boolean
  }) => void
  submitPage: ({
    name,
    values,
    isValid,
  }: {
    name: string
    values: Partial<Value | UseQuizValues>
    isValid: boolean
  }) => void
  isSubmitting: boolean
  isReady: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const QuizContext = createContext<UseQuizResult<UseQuizValues>>(undefined)

export const QuizProvider = function <Value extends FormikValues = FormikValues>({
  id = '',
  children,
  onSubmit,
}: {
  id?: string
  children: React.ReactNode | ((result: UseQuizResult<Value>) => React.ReactNode)
  onSubmit?: (values: Value) => void
}) {
  type SessionData = {
    values: Value
    currentPage: string
    pages: UseQuizResultPages<Partial<Value>>
  }
  const [isReady, setIsReady] = useState<boolean>(false)
  const [isSession, setIsSession] = useState<boolean>(false)
  const { clearMessage } = useNotification()
  const { query: params, isReady: isRouterReady, replace } = useRouter()
  const { getItem, setItem, removeItem } = useStorage()

  // create unique session for quiz (allow page refresh to continue at correct point)
  const [sessionId, setSessionId] = useState<string>()

  // assign session id
  useEffect(() => {
    if (isRouterReady) {
      if (params?.sid) {
        setSessionId(getParam(params.sid))
      } else {
        const uid = uuid()
        setSessionId(uid)
        replace({
          query: { ...params, sid: uid },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRouterReady])

  const sessionKey = `quiz_${id}_${sessionId}`

  // initialize variables (default or restore from session)
  useEffect(() => {
    if (sessionId) {
      const quizSession = getItem<SessionData>(sessionKey, 'session')

      if (quizSession) {
        setCurrentPage(quizSession.currentPage)
        updateValues(quizSession.values)
        updatePages(quizSession.pages)
        setIsSession(true)
      }

      setIsReady(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  const [currentPage, setCurrentPage] = useState<string>()
  const [pages, updatePages] = useReducer<
    Reducer<UseQuizResultPages<Partial<Value>>, UseQuizResultPages<Partial<Value>>>
  >((state, action) => ({ ...state, ...action }), {})
  const [values, updateValues] = useReducer<Reducer<Partial<Value>, Partial<Value>>>(
    (state, action) => ({ ...state, ...action }),
    {},
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  // save changes to session storage
  useEffect(() => {
    if (isReady && (currentPage || pages || values)) {
      setItem(
        sessionKey,
        {
          currentPage,
          pages,
          values,
        },
        'session',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pages, values, isReady])

  // set current page to first if not resume from session
  useEffect(() => {
    if (isReady) {
      const keys = Object.keys(pages)

      if (!currentPage && keys.length > 0) {
        setCurrentPage(keys[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, isReady])

  const registerPage = async ({
    name,
    initialValues,
    isValid = false,
    isComplete = false,
  }: Parameters<UseQuizResult<Value>['registerPage']>[0]) => {
    await updatePages({
      ...pages,
      [name]: {
        values: initialValues,
        isValid,
        isComplete,
      },
    })
    if (!isSession) {
      await updateValues({
        ...values,
        ...initialValues,
      })
    }
  }

  const back = () => {
    clearMessage() //clear any useNotification
    const keys = Object.keys(pages)
    const position = keys.indexOf(`${currentPage}`)

    if (position > 0) {
      setCurrentPage(keys[position - 1])
    }
  }

  const submitPage = ({ name, values: submitValues, isValid }: Parameters<UseQuizResult<Value>['submitPage']>[0]) => {
    setIsSubmitting(true)
    updatePages({
      ...pages,
      [name]: {
        values: submitValues,
        isValid,
        isComplete: true,
      },
    })
    const newValues = {
      ...values,
      ...submitValues,
    }
    updateValues(newValues)

    const keys = Object.keys(pages)
    const position = keys.indexOf(name)

    if (position < keys.length - 1) {
      setCurrentPage(keys[position + 1])
    } else {
      removeItem(sessionKey, 'session')
      onSubmit && onSubmit(newValues as Value)
    }

    setIsSubmitting(false)
  }

  const skip = (page: string) => setCurrentPage(page)

  const result: UseQuizResult<Value> = {
    back,
    skip,
    currentPage,
    values: values as Value,
    pages: pages as UseQuizResultPages<Value>,
    registerPage,
    submitPage,
    isSubmitting,
    isReady,
  }

  return (
    <QuizContext.Provider value={result as UseQuizResult<UseQuizValues>}>
      {typeof children === 'function' ? children(result) : children}
    </QuizContext.Provider>
  )
}

export const useQuiz = (): UseQuizResult<UseQuizValues> => {
  return useContext<UseQuizResult<UseQuizValues>>(QuizContext)
}
