import { stateBGColors, StateBGColors } from './chart-colors'
import { useUi, Breakpoints } from './use-ui'

export type BadgeColors = 'outline' | 'none' | 'amber' | StateBGColors
export type BadgeSizes = 'xs' | 'small' | 'medium' | 'large'
export type BadgePadding = 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl'
export type BadgeType = 'round' | 'square' | 'box'

export interface BadgeProps extends Omit<React.HTMLProps<HTMLSpanElement>, 'size' | 'color' | 'type'> {
  color?: BadgeColors
  size?: Breakpoints<BadgeSizes>
  type?: BadgeType
  padding?: Breakpoints<BadgePadding>
  className?: string
  children: React.ReactNode
}

export const Badge = ({
  color = 'normal',
  size = 'medium',
  type = 'round',
  padding,
  className,
  children,
  ...htmlProps
}: BadgeProps) => {
  if (!padding) {
    padding = size
  }

  const bgColors = {
    ...stateBGColors,
    outline: 'border-1 border-selphAmber-500',
    amber: 'bg-selphAmber-500',
    none: '',
  }

  const textColors = {
    danger: 'text-selphWhite-500',
    info: 'text-selphWhite-500',
    optimal: 'text-selphWhite-500',
    normal: 'text-selphWhite-500',
    warning: 'text-selphWhite-500',
    outline: 'text-selphAmber-500',
    amber: 'text-selphWhite-500',
    none: '',
  }

  const sizes = {
    xs: 'text-xs', // Tailwind: sm:text-xs  md:text-xs  lg:text-xs  xl:text-xs
    small: 'text-sm', // Tailwind: sm:text-sm  md:text-sm  lg:text-sm  xl:text-sm
    medium: 'text-base', // Tailwind: sm:text-base  md:text-base  lg:text-base  xl:text-base
    large: 'text-lg', // Tailwind: sm:text-lg  md:text-lg  lg:text-lg  xl:text-lg
  }

  const paddings = {
    xs: 'px-1.5 py-0.5 ', // Tailwind: sm:px-1.5 sm:py-0.5  md:px-1.5 md:py-0.5  lg:px-1.5 lg:py-0.5  xl:px-1.5 xl:py-0.5
    small: 'px-2.5 py-0.5', // Tailwind: sm:px-2.5 sm:py-0.5  md:px-2.5 md:py-0.5  lg:px-2.5 lg:py-0.5  xl:px-2.5 xl:py-0.5
    medium: 'px-3 py-1', // Tailwind: sm:px-3 sm:py-1  md:px-3 md:py-1  lg:px-3 lg:py-1  xl:px-3 xl:py-1
    large: 'px-3.5 py-1.5', // Tailwind: sm:px-3.5 sm:py-1.5  md:px-3.5 md:py-1.5  lg:px-3.5 lg:py-1.5  xl:px-3.5 xl:py-1.5
    xl: 'px-5 py-3', // Tailwind: sm:px-5 sm:py-3  md:px-5 md:py-3  lg:px-5 lg:py-3  xl:px-5 xl:py-3
    xxl: 'px-7 py-5', // Tailwind: sm:px-7 sm:py-5  md:px-7 md:py-5  lg:px-7 lg:py-5  xl:px-7 xl:py-5
  }

  const types = {
    round: 'rounded-full',
    box: 'rounded-xl',
    square: 'rounded-sm',
  }

  const ui = useUi({
    styles: {
      bgColor: { options: bgColors, selected: color },
      textColor: { options: textColors, selected: color },
      sizes: { options: sizes, selected: size },
      types: { options: types, selected: type },
      paddings: { options: paddings, selected: padding },
    },
    name: 'Badge',
    className: `${className} inline-block`,
  })

  return (
    <span {...htmlProps} className={ui.className}>
      {children}
    </span>
  )
}

Badge.displayName = 'Badge'

export default Badge
