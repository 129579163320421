import { GridSizes } from '../index'
import { Breakpoints } from '../use-ui'
import * as UI from '@/ui'

export type ColumnsPropsContent = string | React.ReactNode

export type ColumnsProps = {
  items: {
    title: string | React.ReactNode
    content: ColumnsPropsContent | ColumnsPropsContent[]
  }[]
  className?: string
}

export const Columns = ({ items, className }: ColumnsProps) => {
  const breakpoints: Breakpoints<GridSizes> = {
    default: 1,
    sm: items.length > 2 ? 2 : (items.length as GridSizes),
    md: items.length > 4 ? 4 : (items.length as GridSizes),
    lg: items.length > 5 ? 5 : (items.length as GridSizes),
    xl: items.length > 7 ? 7 : (items.length as GridSizes),
  }

  return (
    <UI.Grid size={breakpoints} colVerticalAlign="top" className={className}>
      {items.map((item, key) => {
        const contents: ColumnsPropsContent[] = Array.isArray(item.content) ? item.content : [item.content]
        return (
          <UI.Block key={key} gap="none" className={key === items.length - 1 ? 'text-right' : ''}>
            <UI.Paragraph color="darkGrey" size="xs">
              {item.title}
            </UI.Paragraph>
            {contents.map((content: ColumnsPropsContent, key) => (
              <UI.Block gap="none" key={key}>
                {content}
              </UI.Block>
            ))}
          </UI.Block>
        )
      })}
    </UI.Grid>
  )
}

Columns.displayName = 'Panel.Columns'

export default Columns
