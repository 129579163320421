import useUi from '../use-ui'

export type PanelProps = {
  children: React.ReactNode
  bgColor?: 'white' | 'none'
  className?: string
}

export const Panel = ({ children, className, bgColor = 'white' }: PanelProps) => {
  const bgColors = {
    white: 'bg-selphWhite-100',
    none: 'bg-transparent',
  }
  const ui = useUi({
    styles: {
      bgColor: { options: bgColors, selected: bgColor },
    },
    name: 'Panel',
    className: `${className} rounded-xl`,
  })
  return <div className={ui.className}>{children}</div>
}

Panel.displayName = 'Panel'

export default Panel
