import * as UI from '@/ui'

export type SubmitButtonProps = UI.Form.SubmitProps

export const SubmitButton = ({ children, ...props }: SubmitButtonProps) => {
  return <UI.Form.Submit {...props}>{children}</UI.Form.Submit>
}

SubmitButton.displayName = 'Quiz.SubmitButton'

export default SubmitButton
