export type NumberOfLines = 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export type LineHeight = 'small' | 'medium' | 'large' | 'xl'
const lineHeights = {
  small: 'h-3',
  medium: 'h-4',
  large: 'h-5',
  xl: 'h-6',
}

type Gap = 'small' | 'medium' | 'large'
const gaps = {
  small: 'space-y-2',
  medium: 'space-y-4',
  large: 'space-y-6',
}

export type LoadingSkeletonProps = {
  numberOfLines?: NumberOfLines
  lineHeight?: LineHeight
  gap?: Gap
  color?: string
  className?: string
}

export const LoadingSkeleton = ({
  numberOfLines = 3,
  lineHeight = 'medium',
  gap = 'medium',
  color = 'bg-gray-300',
  className = '',
}: LoadingSkeletonProps) => {
  const lines = Array.from({ length: numberOfLines - 1 }, (_, index) => index)

  return (
    <div className={`animate-pulse ${className} ${gaps[gap]}`}>
      <div className={`w-5/6 rounded-sm ${lineHeights[lineHeight]} ${color}`} />

      <div className={`${gaps[gap]}`}>
        {lines.map((index) => (
          <div key={index} className={`rounded-sm ${lineHeights[lineHeight]} ${color}`} />
        ))}
      </div>

      <span className="sr-only">Loading...</span>
    </div>
  )
}

LoadingSkeleton.displayName = 'LoadingSkeleton'

export default LoadingSkeleton
